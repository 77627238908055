import axios from 'axios'
import toast from '../components/toast'
import store from '../store'
import router from '../router'
import { setExpireStore, setStore, getStore, sleep } from '../assets/js/utils'
import alertComp from '../components/alert'
import loadingComp from '../components/loading'

const url = process.env.VUE_APP_API_URL

let config = {
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
}

// 请求拦截
axios.interceptors.request.use(
  function(config) {
    const userToken = store.state.userToken
    config.headers.Authorization = config.headers.Authorization || (userToken && userToken.accessToken ? 'Bearer ' + userToken.accessToken : '')
    config.headers.version = store.state.version
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

const toLogin = str => {
  // refreshTokenError needToken
  // toast('请登记用户信息！')
  store.commit('USER_TOKEN', {})
  console.log(store.state)
  ;(window.location.href.indexOf('code=') < 0 || window.location.href.indexOf('share_code=') > 0) && window.location.href.indexOf('/login') < 0 && setExpireStore('fromPath', window.location.href)
  if (window.location.href.indexOf('code=') < 0) {
    alertComp.removeAll()
    alertComp({
      title: '提示',
      msg: str || '请先登录',
      oneBtn: false,
      closeName: '取消',
      enterName: '登录',
      nextFn: async result => {
        if (result) {
          store.commit('USER_INFO', {})
          window.location.href = `${url}/login/wechat/web/redirect?state=${encodeURIComponent(window.location.href)}&scope=snsapi_userinfo&redirectURI=${encodeURIComponent(window.location.href)}`
        } else {
          location.href = '/'
        }
      }
    })
  }
}

// 是否正在刷新 token
let isRefreshToken = false
// 响应拦截
axios.interceptors.response.use(
  function(response) {
    return response.data
  },
  async function(error) {
    // --------- 存储错误信息 ------
    let errorResponseArr = getStore('errorResponse') || []
    if (errorResponseArr.length > 49) errorResponseArr.splice(49)
    if (error.response && error.response.config) {
      if (errorResponseArr.length > 50) errorResponseArr = errorResponseArr.slice(0, 49)
      errorResponseArr.unshift({
        config: error.response.config,
        method: error.response.config.method,
        url: error.response.config.url,
        data: error.response.config.data,
        headers: error.response.config.headers,
        status: error.response.status,
        resData: error.response.data,
        date: new Date()
      })
      setStore('errorResponse', errorResponseArr)
    }
    // --------- 存储错误信息 end ------

    if (error.response && error.response.status == 401) {
      // 有refreshToken则去刷新token 且 保证请求的地址不是 /login/token/refresh
      if (store.state.userToken && store.state.userToken.refreshToken && (!/\/login\/token\/refresh/.test(error.response.config.url) && !/\/users\/api/.test(error.response.config.url))) {
        if (isRefreshToken) {
          loadingComp.show()
          await sleep(1)
          loadingComp.hide()
          // 变为 false 之后 说明刷新成功了
          if (!isRefreshToken) {
            let config = error.response.config
            config.headers.Authorization = store.state.userToken && store.state.userToken.accessToken ? 'Bearer ' + store.state.userToken.accessToken : ''
            const res = await axios.request(config)
            return Promise.resolve(res)
          }
          return false
        }

        try {
          const data = await refreshToken(store.state.userToken.refreshToken)
          store.commit('USER_TOKEN', data)
          // 刷新完成改为 false
          isRefreshToken = false

          // 重新请求
          let config = error.response.config
          config.headers.Authorization = data && data.accessToken ? 'Bearer ' + data.accessToken : ''
          const res = await axios.request(config)
          return Promise.resolve(res)
        } catch (e) {
          if (e.message == 'refreshIng') {
            // console.log('等待刷新完成')
          } else if (e.response && e.response.data && e.response.data.msg) {
            toLogin(e.response.data.msg)
          } else {
            toLogin('登录已过期，请重新登录!')
          }
        }
      } else {
        toLogin('请先登录')
      }
    } else if (error.response && error.response.status == 403) {
      toLogin('该功能需要登录才能正常使用')
    } else if (error.response && error.response.status == 503) {
      router.replace('/503')
    } else if (error.response && error.response.data && error.response.data.msg) {
      !/wechat\/code\/scenes/.test(error.response.config.url) &&
      !/quizzes\/orders\/coupons/.test(error.response.config.url) &&
        toast(error.response.data.msg)
    } else if (error.response && error.response.status == 429) {
      toast('访问太频繁啦，稍后再试试～')
    } else {
      toast('服务器开小差啦～')
    }
    return Promise.reject(error)
  }
)

const refreshToken = function(refreshToken) {
  return new Promise((resolve, reject) => {
    if (!isRefreshToken) {
      isRefreshToken = true
      resolve(axios.post(`${url}/login/token/refresh`, { refreshToken, oAuthOpenID: store.state.userInfo.openID || '' }, config))
    } else {
      reject(new Error('refreshIng'))
    }
  })
}

export { config, axios, url }
