import Vue from 'vue'
import alertComponts from './alert'

const AlertConstructor = Vue.extend(alertComponts)

let removeDom = event => {
  if (event.target.parentNode) {
    event.target.parentNode.removeChild(event.target)
  }
}

AlertConstructor.prototype.close = function() {
  this.visible = false
  this.$el.addEventListener('transitionend', removeDom)
}

let newalert = (options = {}) => {
  let alert = new AlertConstructor({
    el: document.createElement('div')
  })
  alert.msg = options.msg || options
  alert.align = options.align === 'center' ? 'alert_center' : 'content_center'
  alert.title = options.title || '提示'
  alert.closeName = options.closeName || '取消'
  alert.enterName = options.enterName || '确定'
  if (options.oneBtn === false) {
    alert.oneBtn = false
  } else {
    alert.oneBtn = true
  }
  alert.nextFn = options.nextFn
  document.body.appendChild(alert.$el)
  Vue.nextTick(function() {
    alert.visible = true
    alert.$el.removeEventListener('transitionend', removeDom)
  })
}

newalert.removeAll = function() {
  const doms = document.getElementsByClassName('alert_message_box')
  if (doms && doms.length) {
    for (let o of doms) {
      o.parentNode.removeChild(o)
    }
  }
}

export default newalert
