<template lang='pug'>
#app
  router-view
    
  //- audio(src='./assets/audio/select.mp3' preload ref='selectAudio')
  //- audio(src='./assets/audio/next.mp3' preload ref='nextAudio')
  //- audio(src='./assets/audio/finished.mp3' preload ref='finishedAudio')
</template>

<script>
import Vue from 'vue'
import { setStore, getStore, getQueryString } from '@/assets/js/utils'
import { Howl } from 'howler'

export default {
  name: "App",
  data() {
    return {
      code: ''
    }
  },
  methods: {
    async getOpenID() {
      try {
        const data = await this.$api.postLoginWechatUsers({
          code: this.code,
          state: 'snsapi_userinfo'
        })
        if (data && data.openID && data.accessToken) {
          this.$store.commit('USER_TOKEN', data)

          const user = await this.$api.getUsersCurrentInfo()
          this.$store.commit('USER_INFO', {
            openID: data.openID,
            avatarUrl: user.avatarURL,
            nickName: user.nickName
          })

          let path = getQueryString('state') || `https://${window.location.host}/`
          window.location.replace(decodeURIComponent(path))
        }
      } catch (e) {
        const t = getStore('loginErrorTimes')
        if (t > 3) {
          this.$alert({
            msg: '登录失败次数过多',
            oneBtn: true,
            enterName: '重试',
            nextFn: e => {
              setStore('loginErrorTimes', 0)
              let path = getQueryString('state') || `https://${window.location.host}/`
              window.location.replace(decodeURIComponent(path))
            }
          })
          return
        } else {
          setStore('loginErrorTimes', t ? t + 1 : 1)
          this.$toast('微信登录失败！')
          setTimeout(() => {
            let path = getQueryString('state') || `https://${window.location.host}/`
            window.location.replace(decodeURIComponent(path))
          }, 2000)
        }
      }
    }
  },
  created() {
    const ready = () => {
      // console.log('index ready')
      const code = getQueryString('code')
      this.code = code
      if (code) {
        this.getOpenID()
      }
    }

    if (this.$store.state.isWeixin && (!window.WeixinJSBridge || !WeixinJSBridge.invoke)) {
      document.addEventListener('WeixinJSBridgeReady', ready, false)
    } else {
      ready()
    }
  },
  mounted() {
    const nextAudio = new Howl({
      src: [require('../src/assets/audio/next.mp3')]
    })
    const selectAudio = new Howl({
      src: [require('../src/assets/audio/select.mp3')]
    })
    const finishedAudio = new Howl({
      src: [require('../src/assets/audio/finished.mp3')]
    })

    Vue.prototype.$selectAudio = selectAudio
    Vue.prototype.$nextAudio = nextAudio
    Vue.prototype.$finishedAudio = finishedAudio
  }
};
</script>

<style lang="stylus">
#app
  margin: 0
  padding: 0
  width: 100%
  height: 100%
  position: relative


audio
  opacity: 0
  position: absolute
  pointer-events: none

.page
  width: 100%
  min-height: 100vh
  box-sizing: border-box
  max-width: 7.5rem
  margin: 0 auto
  background: #ddd
  position: relative
</style>>