import Vue from 'vue'
import Vuex from 'vuex'
import api from './api/routes'
import { isWeixin, isSafari, isMobile, setStore, getStore, removeStore } from '@/assets/js/utils'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    comments: {
      c1: {
        title: '秘密宗教的大护法',
        content:
          '你嫉恶如仇，正义感爆棚。对待人和事容易非此即彼，非黑即白。有利的一面是你能快速做出判断，不利的一面则是你无法深究复杂问题下的灰色地带，毕竟我们的世界有时候太复杂，而你有点呆萌，有时候可能顽固不化地成为某一条纲领的大护法。'
      },
      c2: {
        title: '沉迷游戏的修仙者',
        content:
          '你是个善于总结规律的人，对很多事情都有自己独到的见解。但要注意，不要轻易下结论。比如，你接触到的几个来自单亲家庭的孩子都比较害羞，于是你就会认为凡是单亲家庭的孩子都害羞，这种思维方式要避开。有时候因为过于轻率地下结论，你可能对真实世界感到失望透顶，转而去游戏中修仙。'
      },
      c3: {
        title: '自由不羁的艺术家',
        content:
          '你是个对自己很有要求的人，但同时又很害怕没有把事情做好，有时一个不如意就让你心情跌到了谷底。所以看待事情要理性，如果只是细节上的不完美，并不影响整体，更不值得为此浪费了一天的好心情。不如放开一点完美主义的执念，理解艺术的不完美，成为一个自由不羁的艺术家吧。'
      },
      c4: {
        title: '热爱自由的探险家',
        content:
          '你是个喜欢尝试新东西的人，但你可能忽略了，人和人并不一样。有时候你是这么想的，但别人完全不是。比如你觉得生不生孩子纯粹是个人自由，但有人却认为这关系到社会责任。所以，你如果发现有人和你的想法大相径庭，要明白这是正常的。不要一时冲动，就去仇恨某些人，甚至因为这份仇恨而扭曲了自己的心，甚至做出极端的行为。'
      },
      c5: {
        title: '善于计算的智多星',
        content:
          '你是一个经常提前为事情做考虑的人，不太有拖延症。但麻烦的是，你未雨绸缪过了头，经常做最坏最极端的打算，并会因此变得消极。比如一次 KPI 考核你排名靠后，你就认为自己事业上永无出头之日了。要多提醒自己，事情并没有那么糟。既要善于计算提前规划，也要允许事情出现偏差，及时做出调整你就是智多星啦。'
      },
      c6: {
        title: '智慧过人的大明白',
        content:
          '你阳光自信，能力出众，是人群中的佼佼者。但你总是活在自己的舒适区，不愿意去接触更多的新思想，做更多事业上的新尝试。你认为做什么都决不能做 LOSER。所以，有时候多挑战自己，你的生活会更加丰富，也会有更多阅历读懂更多的人和事，成为智慧过人又明了人情世故的大明白。'
      },
      // 你喜欢在心里拿出小本本，给人和事贴标签。比如张小姐经常迟到，李先生经常帮助同事。但你的小本本很乱，都是零碎的事件，你不擅长透过事件去发现规律。这一点使你无法得出明确的结论，但同时有利的是，你也不会轻易觉得某某人是个"坏"人。
      c7: {
        title: '善于思辨的哲学家',
        content:
          '你是个做事之前要考虑很久的人。比如要减肥，你就会去研究各种食谱，弄清热量的概念，了解运动的利弊等等。但有时候你会过于纠缠细节，而迟迟不按下 START 键。对你来说，道理你都懂，那么就行动吧！总是思辨，总是像哲学家一般思考，可能会耽误事。'
      },
      c8: {
        title: '思维缜密的推理者',
        content:
          '你有点像苦行僧，执着地坚持于自己的梦想。虽然经历过挫折，但总有一种精神能让你继续前行。对你来说，最遗憾的是缺少了那么点"运气"。而运气总是垂青有准备的人，所以请相信，只要坚持下去，美好的未来可期。你的思维缜密，你的推理有力，这一切都是你独一无二的优势。'
      },
      c9: {
        title: '专治杠精的好医生',
        content:
          '你是个有点暴躁脾气的聪明人。你最反感的就是某些人太"笨"，你总觉得和笨蛋辩论是浪费精力。可是有点清高的你又会害怕孤独。好吧，别这么曲高和寡了，每个人都有自己"笨"的地方，只要三观还一致，找些人吃个火锅侃侃大山吧。一边侃大山，一边治一治杠精，不要太香哟。'
      },
      c10: {
        title: '泛用人型反谣言决战 AI',
        content:
          '你想过很多事，做过很多事，终于明白了大多数人的思维方式。你希望通过自己的一己之力，影响周围的人，使他们能够更理性地去面对人和事。人类已经不是你的对手， 与 AI 决战"谣言粉碎机"的殊荣，才是你的追求。对你来说，不断接纳这个世界的不完美，还能继续保持乐观，真正的成功人士！'
      }
    },
    isWeixin: false,
    isSafari: false,
    isPC: false,
    user_info: {},
    currentQuizze: {
      id: '',
      isFinnish: false
    },
    questionsData: [],
    paidQuestionsData: {},
    fraction: {}
  },
  mutations: {
    SET_ISWEIXIN(state, v) {
      state.isWeixin = v
    },
    SET_ISSAFARI(state, v) {
      state.isSafari = v
    },
    SET_ISPC(state, v) {
      state.isPC = v
    },
    USER_TOKEN(state, v) {
      state.userToken = v
      setStore('userToken', v)
    },
    USER_INFO(state, v) {
      state.userInfo = v
      setStore('userInfo', v)
    },

    // 问答相关
    CURRENT_QUIZZE(state, v) {
      state.currentQuizze = v
      setStore('currentQuizze', v)
    },
    SET_QUESTIONS_DATA(state, v) {
      state.questionsData = [...v]
    },
    SET_PAID_QUESTIONS_DATA(state, v) {
      state.paidQuestionsData = [...v]
    },
    SET_FRACTION(state, v) {
      state.fraction = v
      setStore('fraction', v)
    }
  },
  actions: {
    // 开始答题
    async startQuizze({ commit, dispatch }) {
      const quizze = await api.postQuizze()
      commit('CURRENT_QUIZZE', {
        id: quizze.id,
        isFinnish: false
      })

      // 获取题目 ID 列表
      await dispatch('getQuizzesQuestions')
    },

    // 获取题目 ID 列表
    async getQuizzesQuestions({ commit, state }) {
      const { list } = await api.getQuizzesQuestionsAnswers(state.currentQuizze.id)
      const answerTagList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O']
      for (let o of list) {
        for (let i in o.answerList) {
          o.answerList[i].tag = answerTagList[i]
        }
        o.answerSelectedList = []
      }

      commit('SET_QUESTIONS_DATA', list)
    },

    // 获取 解析详情
    async getQuestionsPaid({ commit, state }) {
      const { list } = await api.getQuizzesQuestionsAnswersPaid()
      const answerTagList = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O']
      for (let o of list) {
        for (let i in o.answerList) {
          o.answerList[i].tag = answerTagList[i]
        }
        o.answerSelectedList = []
      }

      commit('SET_PAID_QUESTIONS_DATA', list)
    },
    // 获取成绩和排名
    async getFraction({ commit, state }) {
      const fraction = await api.getQuizzeInfo(state.currentQuizze.id)
      commit('SET_FRACTION', fraction)
    }
  }
})

const userInfo = getStore('userInfo') || {}
store.commit('USER_INFO', userInfo)

const userToken = getStore('userToken') || {}
store.commit('USER_TOKEN', userToken)

const currentQuizze = getStore('currentQuizze') || {}
store.commit('CURRENT_QUIZZE', currentQuizze)

const fraction = getStore('fraction') || {}
store.commit('SET_FRACTION', fraction)

store.commit('SET_ISWEIXIN', isWeixin())
store.commit('SET_ISPC', !isMobile())
store.commit('SET_ISSAFARI', isSafari())

export default store
