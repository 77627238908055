// 判断是否是微信客户端
export const isWeixin = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (/MicroMessenger/i.test(ua)) {
    return true
  } else {
    return false
  }
}

export const isSafari = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  let s = ua.match(/version\/([\d.]+).*safari/)
  if (s && s[1]) {
    return s[1]
  } else {
    return false
  }
}

// 判断是否是手机
export const isMobile = () => {
  const ua = navigator.userAgent
  const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
    isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
    isAndroid = ua.match(/(Android)\s+([\d.]+)/),
    isMobile = ipad || isIphone || isAndroid
  return isMobile
}

// 存储localStorage
export const setStore = (name, content) => {
  if (!name) return
  if (typeof content !== 'string') {
    content = JSON.stringify(content)
  }
  window.localStorage.setItem(name, content)
}

// 获取localStorage
export const getStore = name => {
  if (!name || !window.localStorage.getItem(name)) {
    return ''
  }
  return JSON.parse(window.localStorage.getItem(name))
}

export const setExpireStore = (key, val, ex = 3.6e6) => {
  setStore(key, { v: val, t: Date.now() + ex })
}

export const getExpireStore = key => {
  var obj = getStore(key)
  if (obj && obj.t > Date.now()) return obj.v
  else {
    removeStore(key)
    return false
  }
}

// 删除localStorage
export const removeStore = name => {
  if (!name) return
  window.localStorage.removeItem(name)
}

// url QueryStr
export const GetQueryString = name => {
  let after = window.location.href.split('?')[1]
  if (after) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = after.match(reg)
    if (r != null) {
      return decodeURIComponent(r[2])
    } else {
      return null
    }
  }
}

// 美化时间
export const goodTime = str => {
  let now = new Date().getTime()
  let oldTime = new Date(str).getTime()
  let difference = now - oldTime
  let result = ''
  let minute = 1000 * 60
  let hour = minute * 60
  let day = hour * 24
  // let halfamonth = day * 15
  let month = day * 30
  let year = month * 12

  let _year = difference / year
  let _month = difference / month
  let _week = difference / (7 * day)
  let _day = difference / day
  let _hour = difference / hour
  let _min = difference / minute

  if (_year >= 1) {
    result = ~~_year + ' 年前'
  } else if (_month >= 1) {
    result = ~~_month + ' 个月前'
  } else if (_week >= 1) {
    result = ~~_week + ' 周前'
  } else if (_day >= 1) {
    result = ~~_day + ' 天前'
  } else if (_hour >= 1) {
    result = ~~_hour + ' 个小时前'
  } else if (_min >= 1) {
    result = ~~_min + ' 分钟前'
  } else result = '刚刚'
  return result
}

// 格式化日期
export const formatTime = (t, fmt) => {
  let time = new Date(t)
  let o = {
    'M+': time.getMonth() + 1, // 月份
    'd+': time.getDate(), // 日
    'h+': time.getHours() % 12 === 0 ? 12 : time.getHours() % 12, // 小时
    'H+': time.getHours(), // 小时
    'm+': time.getMinutes(), // 分
    's+': time.getSeconds(), // 秒
    'q+': Math.floor((time.getMonth() + 3) / 3), // 季度
    S: time.getMilliseconds() // 毫秒
  }
  let week = {
    '0': '天',
    '1': '一',
    '2': '二',
    '3': '三',
    '4': '四',
    '5': '五',
    '6': '六'
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length > 1 ? (RegExp.$1.length > 2 ? '星期' : '周') : '') + week[time.getDay() + ''])
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
    }
  }
  return fmt
}

// 设置网页字体大小 手机端
export const autoFontSize = () => {
  let setFontSize = () => {
    if (document.documentElement.offsetWidth > 750) {
      document.documentElement.style.fontSize = 100 + 'px'
    } else {
      document.documentElement.style.fontSize = document.documentElement.offsetWidth / 7.5 + 'px'
    }
  }
  setFontSize()
  window.onresize = function() {
    setFontSize()
  }
}

export const sleep = second => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('promise resolved')
    }, second * 1000)
  })
}

export const getQueryString = (name, str) => {
  let after = str || window.location.href.split('?')[1]
  if (after) {
    after = after.split('#')[0]
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    let r = after.match(reg)
    if (r != null) {
      return decodeURIComponent(r[2])
    } else {
      return null
    }
  }
}

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop
  if (c > 0) {
    window.scrollTo(0, 0)
  }
}
