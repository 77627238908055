import api from '@/api/routes'
import wx from 'weixin-js-sdk'
import store from '@/store'

export const regWeChat = async (
  shareJson = {
    title: '理性 VS 感性 2.0——你的感性值超过了多少人？',
    desc: '公司面试，相亲交流，朋友抬杠的有力工具，25 道测试题即可让人褪去伪装，还原真实',
    link: process.env.VUE_APP_BASE_URL,
    imgUrl: 'https://resources.kexueshengyin.com/images/1326781429044367360.jpg'
  }
) => {
  if (!store.state.isWeixin) {
    return
  }
  let signLink = location.href.split('#')[0]
  try {
    const data = await api.getSign({
      url: signLink
    })
    setTimeout(() => {
      wx.config({
        debug: false,
        appId: data.appID,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [
          'updateAppMessageShareData',
          'updateTimelineShareData',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'onMenuShareQZone',
          'closeWindow',
          'previewImage',
          'scanQRCode'
        ]
      })
    }, 200)
    wx.ready(() => {
      // 分享给朋友
      wx.updateAppMessageShareData({
        title: shareJson.title,
        desc: shareJson.desc,
        link: shareJson.link,
        imgUrl: shareJson.imgUrl
      })
      // 分享到朋友圈
      wx.updateTimelineShareData({
        title: shareJson.title,
        link: shareJson.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareJson.imgUrl
      })
      wx.onMenuShareAppMessage({
        title: shareJson.title,
        desc: shareJson.desc,
        link: shareJson.link,
        imgUrl: shareJson.imgUrl
      })
      // 分享到朋友圈
      wx.onMenuShareTimeline({
        title: shareJson.title,
        link: shareJson.link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: shareJson.imgUrl
      })
    })
    wx.error(() => {})
  } catch (e) {
    // console.log(e)
  }
}
