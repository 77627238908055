import Vue from 'vue'
import Router from 'vue-router'
const Index = r => require.ensure([], () => r(require('@/views/Index.vue')), 'Index')
const Question = r => require.ensure([], () => r(require('@/views/Question.vue')), 'Question')
const Result = r => require.ensure([], () => r(require('@/views/Result.vue')), 'Result')
const Share = r => require.ensure([], () => r(require('@/views/Share.vue')), 'Share')
const Answer = r => require.ensure([], () => r(require('@/views/Answer.vue')), 'Answer')

const Error = r => require.ensure([], () => r(require('@/views/Error.vue')), 'Error')

Vue.use(Router)

export default new Router({
  linkActiveClass: 'active',
  mode: 'history',
  routes: [
    {
      path: '/',
      name: '首页',
      component: Index
    },
    {
      path: '/question',
      name: '问题',
      component: Question
    },
    {
      path: '/result',
      name: '结果',
      component: Result
    },
    {
      path: '/share',
      name: '分享',
      component: Share
    },
    {
      path: '/answer',
      name: '解析',
      component: Answer
    },
    {
      path: '*',
      name: '404',
      component: Error
    }
  ]
})
