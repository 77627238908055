import Vue from 'vue'
import './loading.styl'

let Loading = {}
// 避免重复install，设立flag
Loading.installed = false
Loading.install = function(Vue) {
  if (Loading.installed) return
  Vue.prototype.$loading = {}
  Vue.prototype.$loading.show = () => {
    // 如果页面有loading则不继续执行
    if (document.querySelector('#loading')) return
    // 1、创建构造器，定义loading模板
    let LoadingTip = Vue.extend({
      render: h =>
        h(
          'div',
          {
            attrs: {
              id: 'loading'
            }
          },
          [
            h('div', {
              attrs: {
                class: 'loader'
              }
            })
          ]
        )
    })
    // 2、创建实例，挂载到文档以后的地方
    let tpl = new LoadingTip().$mount().$el
    // 3、把创建的实例添加到body中
    document.body.appendChild(tpl)
    // 阻止遮罩滑动
    document.querySelector('#loading').addEventListener('touchmove', function(e) {
      e.stopPropagation()
      e.preventDefault()
    })
    Loading.installed = true
  }
  Vue.prototype.$loading.hide = () => {
    let tpl = document.querySelector('#loading')
    document.body.removeChild(tpl)
  }
}

Loading.show = () => {
  // 如果页面有loading则不继续执行
  if (document.querySelector('#loading')) return
  // 1、创建构造器，定义loading模板
  let LoadingTip = Vue.extend({
    render: h =>
      h(
        'div',
        {
          attrs: {
            id: 'loading'
          }
        },
        [
          h('div', {
            attrs: {
              class: 'loader'
            }
          })
        ]
      )
  })
  // 2、创建实例，挂载到文档以后的地方
  let tpl = new LoadingTip().$mount().$el
  // 3、把创建的实例添加到body中
  document.body.appendChild(tpl)
  // 阻止遮罩滑动
  document.querySelector('#loading').addEventListener('touchmove', function(e) {
    e.stopPropagation()
    e.preventDefault()
  })
  Loading.installed = true
}

Loading.hide = () => {
  let tpl = document.querySelector('#loading')
  tpl && document.body.removeChild(tpl)
}

export default Loading
