<template lang="pug">
  transition(name="bg")
    .alert_message_box(v-show="visible")
      transition(name="con")
        .boxTransition(v-show="visible")
          .content
            .title(v-if="titleShow") {{ title }}
            .bodyCon(:class="align", v-html='msg')

            .btn(v-if="!oneBtn")
              .close(@click="closeFn") {{ closeName }}
              .enter(@click="enterFn") {{ enterName }}
            .btn(v-if="oneBtn")
              .enter(@click="enterFn") {{ enterName }}
</template>

<script>
export default {
  data() {
    return {
      oneBtn: true,
      closeName: '取消',
      enterName: '确定',
      title: '温馨提示',
      align: 'center',
      msg: '',
      visible: false,
      titleShow: true
    }
  },
  methods: {
    enterFn() {
      this.close()
      // history.back()
      window.removeEventListener('popstate', this.closeFn, false)
      if (this.nextFn) {
        this.nextFn(true)
      }
    },
    closeFn() {
      this.close()
      // history.back()
      window.removeEventListener('popstate', this.closeFn, false)
      if (this.nextFn) {
        this.nextFn(false)
      }
    }
  },
  created() {
    // 防止页面关闭 弹窗不关闭
    // history.pushState({}, '')
    window.addEventListener('popstate', this.closeFn, false)
  }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/var'
.bg-enter, .bg-leave-to
  opacity 0
.bg-enter-to, .bg-leave
  opacity 1
.bg-leave-active, .bg-enter-active
  transition all .2s

.con-enter, .con-leave-to
  transform translateY(-20%)
.con-enter-to, .con-leave
  transform translateY(0)
.con-leave-active, .con-enter-active
  transition all .2s

.alert_message_box
  position fixed
  width 100%
  // max-width 7.5rem
  height 100vh
  top 0
  left 50%
  transform translateX(-50%)
  background rgba(0,0,0,0.4)
  text-align center
  z-index 999
  .boxTransition
    width 100%
    // max-width 7.5rem
    height 100vh
    position absolute
  .content
    position absolute
    width 5.7rem
    left 50%
    top 50%
    transform translate(-50%, -50%)
    background $White
    border-radius .1rem
    .title
      padding .4rem 0 0
      text-align center
      font-size .34rem
      height .34rem
      line-height .34rem
      color $Blue
      box-sizing content-box
    .bodyCon
      padding .3rem .36rem 0
      max-height 50vh
      overflow hidden
      margin-bottom .3rem
      &.left
        text-align left
      &.alert_center
        text-align center
    .btn
      display flex
      flex-flow row nowrap
      justify-content space-between
      align-items center
      height .86rem
      line-height .86rem
      position relative
      border-top-1px($elSilver)
      .close, .enter
        text-align center
        flex 1
        font-size .3rem
      .close
        position relative
        border-right-1px($elSilver)
        color $elBlack
      .enter
        color $Blue
@media screen and ( min-width: 751px )
  .alert_message_box
    .content
      transform translate(-4rem, -50%)
</style>
