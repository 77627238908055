<template lang="pug">
  transition(:name='posotion')
    .toast(v-show="visible", :class='posotion')
      .icon(:class='icon', v-if="icon")
      .span(v-html='msg')
</template>

<script>
export default {
  data() {
    return {
      msg: '提示',
      posotion: 'top',
      visible: false,
      icon: ''
    }
  },
  created() {}
}
</script>

<style lang="stylus" scoped>
// 进入前状态
.bottom-enter, .bottom-leave-to
  transform translate(-50%, 100%) !important
  opacity 0

// 进入后状态
.bottom-enter-to, .bottom-leave
  transform translate(-50%, 0) !important
  opacity 1

// 动画之间状态
.bottom-leave-active, .bottom-enter-active
  transition all 0.3s

.top-enter, .top-leave-to, .center-enter, .center-leave-to
  transform translateY(-100%) !important
  opacity 0

.top-enter-to, .top-leave, .center-enter-to, .center-leave
  transform translateY(0) !important
  opacity 1

.top-leave-active, .top-enter-active, .center-leave-active, .center-enter-active
  transition all 0.3s

.toast
  position fixed
  z-index 999

  .icon
    display inline-block

  &.toast_center
    width 2.8rem
    height 2.8rem
    display flex
    flex-flow column nowrap
    justify-content center
    align-items center
    border-radius 0.1rem
    background rgba(0, 0, 0, 0.6)
    position fixed
    top 50%
    left 50%
    margin-left -1.4rem
    margin-top -1.4rem

    .span
      color white
      font-size 0.28rem
      line-height 0.4rem
      text-align center

    .icon
      width 0.8rem
      height 0.8rem
      margin-bottom 0.3rem

      &.loading, &.danger
        background url('./img/danger.png') 0 0 no-repeat
        background-size 0.8rem 0.8rem

  &.top
    top 0
    width 100%
    max-width 7.5rem
    background white
    padding 0.2rem 0.3rem
    box-sizing border-box

    .span
      display inline-block
      font-size 0.3rem
      line-height 0.3rem

    .icon
      position relative
      width 0.3rem
      height 0.3rem
      margin-right 0.1rem
      background red
      vertical-align top

  &.bottom
    bottom 2rem
    left 50%
    transform translate(-50%, 0)

    .icon
      display none

    .span
      text-align center
      line-height 0.4rem
      background rgba(0, 0, 0, 0.6)
      color white
      font-size 0.28rem
      padding 0.1rem 0.3rem
      border-radius 0.1rem
</style>
