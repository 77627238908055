import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'

// 注册api
import '@/api'
// 注册全局组件
import '@/components'

// 初始化样式
import './assets/css/init.styl'

// jssdk
import wx from 'weixin-js-sdk'
// 注册微信jssdk
window.wx = wx

Vue.config.productionTip = false

// 微信分享
import { regWeChat } from '@/assets/js/wx'
import { setStore, getStore, removeStore, setExpireStore, getExpireStore, formatTime } from '@/assets/js/utils'
Vue.prototype.$regWeChat = regWeChat
Vue.prototype.$setStore = setStore
Vue.prototype.$getStore = getStore
Vue.prototype.$removeStore = removeStore
Vue.prototype.$setExpireStore = setExpireStore
Vue.prototype.$getExpireStore = getExpireStore
Vue.prototype.$formatTime = formatTime

import { autoFontSize } from '@/assets/js/utils'
autoFontSize()

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


router.afterEach(to => {
  document.title = to.meta.title || '科学声音'
})