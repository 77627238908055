import { config, axios, url } from './config'

export default {
  getSign: json => axios.get(`${url}/wechat/js_sdk/config`, { headers: config.headers, params: json }),
  putUsersCurrent: params => axios.put(`${url}/users/current`, params, config),
  postLoginWechat: params => axios.post(`${url}/login/wechat/web`, params, config),
  postLoginWechatUsers: params => axios.post(`${url}/login/wechat/web/users`, params, config),
  getPhonesSmsCode: params => axios.get(`${url}/login/phones/sms/code`, { headers: config.headers, params }),
  postPhonesSmsCode: params => axios.post(`${url}/login/phones/sms/code`, params, config),
  postLoginAccounts: params => axios.post(`${url}/login/accounts`, params, config),

  getWechatCode: params => axios.get(`${url}/wechat/code`, { headers: config.headers, params }),
  getWechatCodeScenes: (sceneID, params) => axios.get(`${url}/wechat/code/scenes/${sceneID}`, { headers: config.headers, params }),

  // 获取头像上传凭证
  getAvatarsUploadPolicyToken: params => axios.get(`${url}/users/current/info/avatars/upload_policy_token`, { headers: config.headers, params }),
  getUsersCurrentInfo: (params, conf = config) => axios.get(`${url}/users/current/info`, { headers: conf.headers, params }),
  putUsersCurrentInfo: params => axios.put(`${url}/users/current/info`, params, config),

  // p, member, /quizzes/questions, GET 废弃

  // p, member, /quizzes, POST
  // p, member, /quizzes/:quizID, PUT
  // p, member, /quizzes/:quizID/status/finished, PUT
  // p, member, /quizzes/:quizID, GET
  // p, member, /quizzes/:quizID/questions, GET
  // p, member, /quizzes/questions/:questionID, GET
  postQuizze: params => axios.post(`${url}/quizzes`, params, config),
  putQuizze: (quizID, params) => axios.put(`${url}/quizzes/${quizID}`, params, config),
  putQuizzeFinished: (quizID, params) => axios.put(`${url}/quizzes/${quizID}/status/finished`, params, config),
  getQuizzeInfo: (quizID, params) => axios.get(`${url}/quizzes/${quizID}`, { headers: config.headers, params }), // 获取成绩和排名
  getQuizzesQuestions: (quizID, params) => axios.get(`${url}/quizzes/${quizID}/questions`, { headers: config.headers, params }),
  getQuizzesQuestionsAnswers: (quizID, params) => axios.get(`${url}/quizzes/${quizID}/questions/answers`, { headers: config.headers, params }),
  getQuizzesQuestionInfo: (quizID, questionID, params) => axios.get(`${url}/quizzes/questions/${questionID}`, { headers: config.headers, params }),

  postQuizzeOrders: (quizID, params) => axios.post(`${url}/quizzes/${quizID}/orders`, params, config),
  postQuizzeOrdersPaysWechat: (orderID, params) => axios.post(`${url}/quizzes/orders/${orderID}/pays/wechat`, params, config),
  getQuizzesQuestionsPaid: (questionID, params) => axios.get(`${url}/quizzes/questions/${questionID}/paid`, { headers: config.headers, params }),
  getQuizzesQuestionsAnswersPaid: (questionID, params) => axios.get(`${url}/quizzes/questions/answers/paid`, { headers: config.headers, params }),
  getQuizzesOrdersCoupons: params => axios.get(`${url}/quizzes/orders/coupons`, { headers: config.headers, params }),
  getQuizzes: params => axios.get(`${url}/quizzes`, { headers: config.headers, params }),
  getQuizzesResults: (quizID, params) => axios.get(`${url}/quizzes/${quizID}/results`, { headers: config.headers, params }),
}
