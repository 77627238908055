import Vue from 'vue'
import toastComponts from './toast'

const ToastConstructor = Vue.extend(toastComponts)

let removeDom = event => {
  if (event.target.parentNode) {
    event.target.parentNode.removeChild(event.target)
  }
}

ToastConstructor.prototype.close = function() {
  this.visible = false
  this.$el.addEventListener('transitionend', removeDom)
  this.closed = true
}

let toast = (options = {}) => {
  let toast = new ToastConstructor({
    el: document.createElement('div')
  })
  let showTime = options.time || 2500
  toast.closed = false
  toast.msg = options.msg || options
  toast.icon = options.icon
  toast.posotion = options.posotion === 'center' ? 'toast_center' : 'bottom'
  document.body.appendChild(toast.$el)

  Vue.nextTick(function() {
    toast.visible = true
    toast.$el.removeEventListener('transitionend', removeDom)
    showTime &&
      (toast.timer = setTimeout(function() {
        if (toast.closed) return
        toast.close()
      }, showTime))
  })

  return toast
}

export default toast
